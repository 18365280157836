

import React from "react"
import { Container } from "reactstrap"
import { Link } from "gatsby"
import defaultHero from '../images/fahr_erlebnis_weiss_transparent.png';

import "../styles/components/hero.scss"
import heroBild from "../images/chappi/01.jpg"

import companyThumbnail from "../images/chappi/logo_weiss_1.png"


const Hero = ({
    title="Coming soon – stay tuned!",
    subtitle="Qualitätskaffee aus Vietnam",
    image,
    fullScreen=false
}) => {
    console.log(image)

  return (
    <div
      className="hero"
      id="hero"
      style={{
        backgroundPosition:'center',
        backgroundSize:'cover',
        backgroundRepeat:'no-repeat',
        background: `linear-gradient( rgba(0, 0, 0, 0.11), rgba(0, 0, 0, 0.11) ), 
        url(${heroBild})`, height: fullScreen ? '100vh' : '70vh' 
      }}
    >
      <section className="h-100 d-flex align-items-center text-center bg-dark-100">
        <Container>
        <img
            src={companyThumbnail}
            alt="Company Thumbnail"
            className="img-head-home"
        />  
          <main className="hero-content">
          <h1 className="title">{subtitle}</h1>
            

          {/* <p className="hero-subber">{subtitle}</p> */}
          </main>
        </Container>
      </section>
    </div>
  )
}

export default Hero
